import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./core/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./core/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'detailsmodal',
    loadChildren: () =>
      import('./components/detailsmodal/detailsmodal.module').then(
        (m) => m.DetailsmodalPageModule
      ),
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./core/payment/payment.module').then((m) => m.PaymentPageModule),
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('./core/subscription/subscription.module').then(
        (m) => m.SubscriptionPageModule
      ),
  },

  {
    path: 'donate',
    loadChildren: () =>
      import('./pages/donate/donate.module').then((m) => m.DonatePageModule),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
