import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  darkMode: boolean = true;
  constructor() {
    // document.body.setAttribute('data-theme', 'dark');
  }
}
